.ProjectHeader
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  min-height: 186px
  margin-bottom: 0
  padding: 0 50px
  font-weight: bold
  font-size: 26px
  line-height: 31px
  text-transform: uppercase
  background: #ffffff
  &_logo
    display: block
    margin-bottom: 10px
  &_bottom
    margin: 0
    font-size: 12px
    line-height: 12px
    color: #A7A8B9
  &_lesson
    margin-right: 284px
    .ProjectHeader_wrap
      width: 100%
      max-width: 864px
      margin: 0 auto
  @media screen and (max-width: 1366px)
    display: none
